<template>
  <v-container class="grey lighten-5">
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
            style="font-size: 10px;"
        >
          <v-img
              :width="158"
              src="/emclogo.png"
          ></v-img>
          <br>
          <b>Electrical Mechanical & Cooling Ltd</b><br>
          2 Ellerbeck Ct, Stokesley Business Park,<br>
          Stokesley, North Yorkshire, TS9 5PT<br>
          <b>Email:</b> sarah.williams@emcltd.co<br>
          <b>Website:</b> www.emcltd.co
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2 text-center"
            tile
            outlined color="transparent"
        >
          <h1>VO Site Survey Form</h1>
          <v-img
              :width="376"
              src="/emc-accreds.png"
          ></v-img>
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
            align="right"
        >
          <b>Enquiry Reference: </b>{{vosurvey.enquiryReference}}<br>
          <b>Site Name:</b> {{vosurvey.siteName}}<br>
          <b>Site Postcode:</b> {{vosurvey.sitePostcode}}<br><br>
          <b>Survey Date:</b>{{vosurvey.surveyDate}}<br><br>

          <br />

        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="12"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
        </v-card>
      </v-col>
    </v-row>

    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="6"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >

        </v-card>
      </v-col>
      <v-col
          :cols="6"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >

        </v-card>
      </v-col>

    </v-row>


    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View VO Commissioning Form'
  },
  data() {
    return {
      vosurvey: {
      },
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
    }
  },
  computed: {
  },
  methods: {
    getVosurvey() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('vosurveys/get', this.$route.params.id).then((result) => {
              this.vosurvey = result;
            }
        );
      }
    }
  },
  mounted() {
    this.getVosurvey();
  }
}
</script>
<style>
dl {
  padding: 0.5em;
}
dt {
  float: left;
  clear: left;
  width: 400px;
  text-align: right;
  font-weight: bold;
  color: green;
}
dt::after {
  content: ": ";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.7em 0;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
